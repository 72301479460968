import React, { useEffect, useState } from "react";
import { Card, Col, Layout, Row, Table, Typography, Divider } from "antd";
import { Column, Pie } from "@ant-design/charts";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { 프로필이벤트Store } from "../../stores/mediprofilelogStore";

const { Title } = Typography;
const { Content } = Layout;

export interface ProfileEvent {
    id: number;
    createdDate: string;
    uid: string;
    idNumber: string;
    eventType: string;
    eventDetail: string;
    name: string;
}

const Dashboard: React.FC = observer(() => {
    const [dataSource, setDataSource] = useState<ProfileEvent[]>([]);
    const [nameData, setNameData] = useState<{ name: string; value: number }[]>([]);
    const [eventTypeData, setEventTypeData] = useState<{ type: string; value: number }[]>([]);

    useEffect(() => {
        const loadData = async () => {
            await 프로필이벤트Store.loadProfileEvents();
            const eventsData = 프로필이벤트Store.profileEventsData;
            setDataSource(eventsData);

            // 이름별 데이터 집계, null과 undefined를 처리
            const nameCount = eventsData.reduce((acc: { [key: string]: number }, event) => {
                const name = event.name || "이름 없음";
                acc[name] = (acc[name] || 0) + 1;
                return acc;
            }, {});
            setNameData(Object.entries(nameCount).map(([name, count]) => ({ name, value: count })));

            // 이벤트 타입별 데이터 집계, null과 undefined를 처리
            const eventTypeCount = eventsData.reduce((acc: { [key: string]: number }, event) => {
                const type = event.eventType || "타입 없음";
                acc[type] = (acc[type] || 0) + 1;
                return acc;
            }, {});
            setEventTypeData(Object.entries(eventTypeCount).map(([type, count]) => ({ type, value: count })));
        };

        loadData();
    }, []);

    const columns = [
        { title: "순번", dataIndex: "id", render: (_: any, __: any, index: any) => index + 1 },
        { title: "생성 날짜", dataIndex: "createdDate", render: (text: any) => dayjs(text).format("YYYY-MM-DD HH:mm:ss") },
        { title: "이름", dataIndex: "name" },
        // { title: "UID", dataIndex: "uid" },
        { title: "이벤트 타입", dataIndex: "eventType" },
        { title: "이벤트 세부 정보", dataIndex: "eventDetail" },

    ];

    const nameColumnConfig = {
        data: nameData,
        xField: "name",
        yField: "value",
        color: "#5B8FF9",
        xAxis: {
            title: { text: "이름", style: { fontSize: 14, fontWeight: "bold" } },
            label: { rotate: -30, style: { fontSize: 12 } },
        },
        yAxis: {
            title: { text: "이름별 개수", style: { fontSize: 14, fontWeight: "bold" } },
        },
        tooltip: {
            formatter: (datum: { name: string; value: number }) => ({ name: "이름별 개수", value: datum.value }),
        },
    };

    const eventTypePieConfig = {
        appendPadding: 10,
        data: eventTypeData,
        angleField: "value",
        colorField: "type",
        radius: 0.8,
        label: {
            // type: "outer",
            content: "{name} {percentage}",  // `type` 필드에서 `name` 필드로 변경
        },
        interactions: [{ type: "element-active" }],
        tooltip: {
            formatter: (datum: { type: string; value: number }) => ({ name: "이벤트 타입별 개수", value: datum.value }),
        },
    };

    return (
        <Layout style={{ padding: "24px" }}>
            <Content>
                <Title level={2}>Profile Events Dashboard</Title>

                <Row gutter={16}>
                    <Col span={16}>
                        <Card title="Profile Events 목록">
                            <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={{ pageSize: 10 }} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="이름별 분포" >
                            <Column {...nameColumnConfig} height={250} /> {/* 차트 높이 조정 */}
                        </Card>
                        <Divider />
                        <Card title="이벤트 타입별 분포" >
                            <Pie {...eventTypePieConfig} height={250} /> {/* 차트 높이 조정 */}
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
});

export default Dashboard;