import GlobalStyle from "./components/styled/GlobalStyles";
import React, { useState } from 'react';
import UserRequestForm from "./UserRequestForm";
import UserInfo from "./UserInfo";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import Nav from "../src/components/Nav";
import Login from "./components/login/Login";
import DownloadTest from "./DownloadTest";
import UserInfoPage from "./pages/admin/UserInfoPage";
import MakeVcf from "./pages/admin/MakeVcf";
import RequestFormV2 from "./pages/RequestFormV2";
import Dashboard from "./pages/admin/DashBoard";

// import theme from "./Theme/theme";

const Wrapper = styled.div`
    //background: #e6ebff;
    display: flex;
    align-items: center;
    justify-content: center;
`;


function App() {
    // 인증 상태 확인: auth_token이 존재하는지 확인
    const [isAuthenticated, setAuth] = useState(!!sessionStorage.getItem('auth_token'));
    return (
        <BrowserRouter>
            <Nav/>
            <GlobalStyle/>
            <Wrapper>
                <Routes>
                    <Route path="/" element={<RequestFormV2/>}/>
                    <Route path="/login" element={<Login setAuth={setAuth}/>}/>
                    <Route path="/download" element={<DownloadTest/>}/>
                    <Route path="/makevcf" element={<MakeVcf/>}/>
                    <Route
                        path="/admin"
                        element={isAuthenticated ? <UserInfoPage /> : <Navigate to="/login" replace />}
                    />
                    <Route
                        path="/dashboard"
                        element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />}
                    />
                </Routes>
            </Wrapper>
        </BrowserRouter>
    )
}

export default App;